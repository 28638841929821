// store/appointmentsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Appointment, AppointmentsState } from '../apiClient/models/appointment';
import { GetAppointments } from '../apiClient/services';

const initialState: AppointmentsState = {
  appointments: [],
  total:0,
  currentPage:1,
  loading: false,
  error: null,
};

export const fetchAppointments = createAsyncThunk<
  {data:Appointment[], total:number},
  { page: number; perPage: number, advisorId:string, userId:string, sort:"upcoming" | "past" | "", name:string, startDate:string, endDate:string },
  { rejectValue: string }
>('appointments/fetchAppointments', async ({ page, perPage, advisorId, userId, sort="", name="", startDate="", endDate="" }, { rejectWithValue }) => {
  try {
    const response = await GetAppointments(perPage, page,advisorId, userId, sort, name, startDate, endDate)
    return response
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointments.fulfilled, (state, action: PayloadAction<{data:Appointment[], total:number}>) => {
        state.appointments = action.payload.data;
        state.total = action.payload.total
        state.loading = false;
      })
      .addCase(fetchAppointments.rejected, (state, _action) => {
        state.loading = false;
        state.error = 'Failed to fetch appointments';
      });
  },
});

export const { setCurrentPage } = appointmentsSlice.actions
export default appointmentsSlice.reducer;
