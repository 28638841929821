import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { authClient, userClient } from "../../../apiClient/apiClient";
import { UserState, LoginResponse, LogoutResponse, RegisterResponse, WrongResponse, IProfile } from '../../../apiClient/models/user';
import { GetProfile } from '../../../apiClient/services';
import { OauthProvider } from '../../../client';
import { AxiosResponse } from 'axios';
import { getDeviceId } from '../../../utils/commonFuncs';

const initialState: UserState = {
  loading: false,
  accessToken: localStorage.getItem('accessToken') || '',
  user: null,
  error: ""
};

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async ({ username, password }: { username: string; password: string }, thunkAPI) => {
    try {
      const payload:any = {
        password:password,
        email: username.includes("@") ? username : "",
        phone: username.includes("@") ? "" : username,
        deviceId:getDeviceId(),
        deviceType:"web"
      }
      const response = await authClient().post<LoginResponse>('/login', payload);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);

export const getProfile = createAsyncThunk(
  'user/getProfile',
  async (_, thunkAPI) => {
    try {
      const response = await GetProfile()
      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async ({ email, password, phone }:{email?:string, password:string, phone?:string}, thunkAPI) => {
    try {
      const response = await authClient().post<RegisterResponse>('/register', { email, password, phone, registerDevicePlatform:"web", notificationPermission:3 });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data as WrongResponse);
      } else {
        return thunkAPI.rejectWithValue({ statusCode: 500, error: 'Internal Server Error', message: 'An error occurred' });
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userClient().post<LogoutResponse>('/user/logout');
      localStorage.removeItem("persist:root")
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);

export const loginWithOAuth = createAsyncThunk(
  'auth/loginWithOAuth',
  async (payload: {
    oauthProvider: OauthProvider,
    providerToken: string,
  }) => {
    try {
      const { oauthProvider, providerToken } = payload
      const response:AxiosResponse<{data:{accessToken:string, user:any}}> = await authClient().post('/oauth', {
        provider: oauthProvider,
        token: providerToken,
        deviceType:"web"
      })
      
      return response.data
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    setCredentialsForSebit(state,action) {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    setUser: (state, action: PayloadAction<UserState['user']>) => {
      state.user = action.payload;
    },
    toggleTeacherMode: (state) => {
      if (state.user) {
        state.user.isUserTeacher = !state.user.isUserTeacher;
      }
    },
    logOut(state) {
      localStorage.removeItem("persist:root")
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.accessToken = ""
      state.user = null
    },
    setAuthError(state, action) {
      state.error = action.payload
    },
    setLoading(state,action) {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(loginUser.pending, (state, action) => {
      state.loading = true
    })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<LoginResponse>) => {
        state.accessToken = action.payload.data.accessToken;
        // state.user = {...action.payload.data.user, isUserTeacher: false}
        localStorage.setItem('accessToken', action.payload.data.accessToken);
        localStorage.setItem('refreshToken', action.payload.data.session.refreshToken);
        state.loading = false
      })
      .addCase(loginUser.rejected, (_state, action) => {
        console.error('Login failed:', action.payload);
        _state.loading = false
      })
      .addCase(registerUser.pending, (state, action) => {
        state.loading = true;
        state.error = ''
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<RegisterResponse>) => {
        state.accessToken = action.payload.data.accessToken;
        state.user = action.payload.data.user;
        localStorage.setItem('accessToken', action.payload.data.accessToken);
      })
      .addCase(registerUser.rejected, (_state, action) => {
        _state.loading = false
        _state.error = action.error.message || ''
        if (action.payload) {
          console.error('Register failed:', action.payload);
        } else {
          console.error('Register failed');
        }
      })
      .addCase(logoutUser.fulfilled, (state, _action: PayloadAction<LogoutResponse>) => {
        state.user = null;
        state.accessToken = '';
        localStorage.removeItem('accessToken');
      })
      .addCase(logoutUser.rejected, (_state, action) => {
        console.error('Logout failed:', action.payload);
      })
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getProfile.fulfilled, (state, action: PayloadAction<{data:IProfile}>) => {
        state.user = {...action.payload.data, isUserTeacher: false}
        state.loading = false
      })
      .addCase(getProfile.rejected, (_state, action) => {
        _state.loading = false
        console.error('Get Profile Store Failed:', action.payload);
      })
      builder.addCase(loginWithOAuth.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(loginWithOAuth.fulfilled, (state, action) => {
        state.accessToken = action.payload.data.accessToken;
        state.user = action.payload.data.user
        state.loading = false;
      });
      builder.addCase(loginWithOAuth.rejected, (state, action) => {
        state.loading = false
      })
  },
});

export const { setAccessToken, setCredentialsForSebit, setUser, toggleTeacherMode, logOut, setAuthError, setLoading } = userSlice.actions;
export default userSlice.reducer;
