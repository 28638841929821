import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITrainingSet, ITrainingSetList } from "../apiClient/models/trainingSet";
import { ContentSearch, GetAllArticles, GetAllTrainingSets } from "../apiClient/services";
import { IArticle } from "../apiClient/models/article";

export const contentSearch = createAsyncThunk(
    'search/contentSearch',
    async(payload:{query:string}) => {
        try {
            return await ContentSearch(payload.query)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string
    search_text:string
    trainingSetSearchItems: Array<ITrainingSet>
    articleSearchItems: Array<IArticle>
}

const initialState: IInitialState = {
    loading:false,
    error:"",
    search_text:"",
    trainingSetSearchItems: [] as ITrainingSet[],
    articleSearchItems: [] as IArticle[]
}

export const searchSlice = createSlice({
    name:"search",
    initialState,
    reducers: {
        setLocalSearchWord(state,action){
            state.search_text= action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(contentSearch.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(contentSearch.fulfilled, (state, action) => {
            state.trainingSetSearchItems = action.payload.data.trainingSet
            state.articleSearchItems = action.payload.data.article
            state.search_text = action.meta.arg.query
            state.loading = false
        })
        builder.addCase(contentSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export const { setLocalSearchWord } = searchSlice.actions
export default searchSlice.reducer