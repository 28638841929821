import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux'
import parentSolutionReducer from '../parentSolutionSlice'
import authReducer from '../authSlice'
import articleReducer from '../articleSlice';
import favoriteReducer from '../favoriteSlice';
import surveyReducer from '../surveySlice'
import parentSchoolReducer from '../parentSchoolSlice'
import logReducer from '../logSlice'
import bulettinReducer from '../bulettinSlice'
import childReducer from '../childSlice'
import areaOfInterestReducer from '../areaOfInterestSlice'
import searchReducer from '../searchSlice'
import purchaseReducer from '../purchaseSlice'
import generalControllerReducer from '../generalControllerSlice'
import learnWithQuestionReducer from '../learnWithQuestionSlice'
import notificationReducer from '../notificationSlice'
import authMicroReducer from "../clientStore/userSlice/userSlice";
import appointmentReducer from '../appointmentSlice'

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  persistStore,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth','favorite','log']
}

const rootReducer = combineReducers({
  auth:authReducer,
  parentSolution:parentSolutionReducer,
  article:articleReducer,
  favorite:favoriteReducer,
  survey:surveyReducer,
  parentSchool:parentSchoolReducer,
  log:logReducer,
  bulettin:bulettinReducer,
  child:childReducer,
  areaOfInterest:areaOfInterestReducer,
  search:searchReducer,
  purchase:purchaseReducer,
  generalController:generalControllerReducer,
  learnWithQuestion:learnWithQuestionReducer,
  notification:notificationReducer,
  appointment: appointmentReducer,

  // for other client api
  authMicro: authMicroReducer 
})

const _persistReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: _persistReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
