import { AxiosResponse } from "axios";
import { RefreshSessionResponse } from "../models/user";
import { authClient } from "../apiClient";

export const refreshSession = async(): Promise<any> => {
    try {
        const response: AxiosResponse<RefreshSessionResponse> = await authClient().post(`/refresh`, {
            refreshToken: localStorage.getItem('refreshToken') || "",
            deviceType: "web"
        });

        return response.data.data.accessToken;
    } catch (error:any) {
        console.error('Error refresh session:', error.response.status, error.response.data.message);
        let errorMessage = "Session hatası."

        switch (error.response.data.message) {
            case "SESSION_CLOSED: TIMEOUT":
                errorMessage = "Bağlantı zaman aşımına uğradı."
                break;
            case "SESSION_CLOSED: MULTIPLE_LOGIN":
                errorMessage = "Birden fazla cihazdan giriş algılandı."
                break;
            default:
                break;
        }
        throw new Error(errorMessage || "Session hatası ile karşılaşıldı.")
    }
}