import { FC,MouseEvent } from "react";
import { DoubleChatBallon } from "../assests";
import { Typography } from "@mui/material";
import ArticleListingItem from "./ArticleListingItem";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { FavoriteType } from "../client";

interface Props {
  content_id: string;
  content_title: string;
  time: string
}

const AiChatContentSuggestion: FC<Props> = ({ content_id, content_title, time }) => {
  const dispatch = useDispatch<AppDispatch>();

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          favoritableId: id,
          favoritableType:"Article",
          name:name_of_content
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favoritableId: id, favoritableType:"Article" }));
    } catch (error) {}
  };

  return (
    <div
      style={{
        paddingRight: "20%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width:"fit-content"
      }}
    >
      <div
        style={{
          borderRadius: "0px 15px 15px 15px",
          padding: "15px 20px",
          backgroundColor: "#ECF1F6",
          textAlign: "left",
          width: "fit-content",
        }}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "#2574FF",
            width: "fit-content",
            borderRadius: "50%",
            display: "flex",
            marginBottom: "21px",
          }}
        >
          <img src={DoubleChatBallon} alt="" />
        </div>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "25px",
            color: "#434E58",
            marginBottom:"28px"
          }}
        >
          Senin için bu makaleyi önerebilirim:
        </Typography>
        <div style={{
            width:"60%"
        }}>
        {
            [...Array(1)].map((e, index) => {
                return(
                    <ArticleListingItem
                    id={Number(content_id)}
                    title={content_title}
                    is_picture_uploaded={true}
                    addContentToFavorite={(e,id, name_of_content) =>
                      addContentToFavorite(e,id, name_of_content)
                    }
                    removeContentFromFavorite={(e, id) =>
                      removeContentFromFavorite(e,id)
                    }
                  />
                )
            })
        }
        </div>
      </div>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "20px",
          color: "#78828A",
          textAlign: "right",
        }}
      >
        {time}
      </Typography>
    </div>
  );
};

export default AiChatContentSuggestion;
