import { FC, useEffect, MouseEvent } from "react";
import { Container, Grid } from "@mui/material";
import {
  CommonLoading,
  HeadingTitle,
  ParentSolutionListingItem,
  NoRecordParentSolutionLog,
  ProgressParentSolutionSlider,
  PaginationItem,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getAllParentSolutions } from "../redux/parentSolutionSlice";
import {
  ArticleType,
  ProgressStatusType,
  TrainingSetArticleItem,
} from "../client";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { FavoriteType, OpenAPI} from "../client";

const ParentSolutions: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { trainingSets, loading } = useSelector(
    (state: RootState) => state.parentSolution
  );

  const { progress_status_items } = useSelector(
    (state: RootState) => state.log
  );

  const DATA_LENGTH_LIMIT = 30

  useEffect(() => {
      dispatch(
        getAllParentSolutions({
          perPage:DATA_LENGTH_LIMIT, page:1, name:"",
        })
      );
  }, []);

  const totalWatchTimeCalculator = (sec:number) => {
    if (sec == 0) {
      return 0;
    }

    return Math.floor(sec / 60);
  };

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          favoritableType:"TrainingSets",
          favoritableId:id,
          name: name_of_content
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favoritableId: id, favoritableType:"TrainingSets"}));
    } catch (error) {}
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Öğrenmeye Devam Et" _marginBottom="0px" />
        </Grid>
        {progress_status_items.length > 0 ? (
          <Grid item xs={4} sm={8} md={12} lg={12}>
            <ProgressParentSolutionSlider
              items={progress_status_items
                .filter(
                  (k) =>
                    k.progress_status_type == ProgressStatusType.TRAINING_SET
                )
                .map((e) => {
                  return {
                    id: e.item_id,
                    title: e.item_name,
                    percentage:
                      (100 * e.completed_item_count) / e.total_items_count,
                  };
                })}
            />
          </Grid>
        ) : (
          <Grid item xs={4} sm={8} md={4} lg={4} xl={3}>
            <NoRecordParentSolutionLog />
          </Grid>
        )}
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          lg={12}
          style={{
            marginTop: "28px",
            textAlign: "start",
          }}
        >
          <HeadingTitle title="Tüm Ebeveyn Çözümleri" _marginBottom="0px" />
        </Grid>
        {!loading &&
          trainingSets.data.map((e, index) => {
            return (
              <Grid item xs={4} sm={8} md={4} lg={4} xl={3}>
                <ParentSolutionListingItem
                  id={e.id}
                  title={e.name}
                  video_article_count={
                    e.videoArticleCount
                  }
                  text_article_count={
                    e.textArticleCount
                  }
                  total_watch_time={
                    totalWatchTimeCalculator(e.videoDurationInSecond)
                  }
                  is_picture_uploaded={
                    e?.isPictureUploaded ? e.isPictureUploaded : false
                  }
                  addContentToFavorite={(e, id, name_of_content) =>
                    addContentToFavorite(e, id, name_of_content)
                  }
                  removeContentFromFavorite={(e, id) =>
                    removeContentFromFavorite(e, id)
                  }
                  percentage={
                    e.watchPercent
                  }
                />
              </Grid>
            );
          })}
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ marginTop: "10px" }}>
          <PaginationItem currentPage={trainingSets.currentPage} totalPages={Math.ceil(trainingSets.total / DATA_LENGTH_LIMIT)} onPageChange={(e) => dispatch(getAllParentSolutions({perPage:DATA_LENGTH_LIMIT, page:e, name:""}))}/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ParentSolutions;
