import { AxiosResponse } from "axios";
import { contentClient } from "../apiClient";
import { IFavorites } from "../models/favorite";

export const GetAllFavorites = async (): Promise<IFavorites> => {
    try {
        const response: AxiosResponse<IFavorites> = await contentClient().get("/favorite")
        return response.data;
    } catch (error) {
        console.error('Error fetching favorites:', error);
        throw new Error('Failed to fetch favorites');
    }
}

export const ToggleFavorite = async (favoritableType: string, favoritableId: string): Promise<boolean> => {
    try {
        const response: AxiosResponse<{ success: boolean }> = await contentClient().post("/favorite", {
            favoritableType,
            favoritableId
        })
        return response.data.success
    } catch (error) {
        console.error('Error toggle favorite:', error);
        throw new Error('Failed to toggle favorite');
    }
}