import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { On1Service, SebitService } from "../client";
import { CommonErrorModalNoShadow, CommonLoading } from "../components";
import { setCredentialsForSebit } from "../redux/clientStore/userSlice/userSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { logOut } from "../redux/clientStore/userSlice/userSlice";
import { logOutFavs } from "../redux/favoriteSlice";
import { logOutLogs } from "../redux/logSlice";
import { authClient } from "../apiClient/apiClient";

const On1InitialPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setLoading(true);
    if (token == undefined || token == null || token == "") {
      navigate("/login");
    } else {
      (async () => {
        try {
          await logOutTrigger();

          const checkUser = await authClient().post("/on1/login", {
            on1Token: token,
          });

          const data = checkUser.data.data;

          if (data.hasRegistered) {
            dispatch(setCredentialsForSebit(data.appAccessCredentials?.accessToken))
            setTimeout(() => navigate('/home') ,500);
          } else {
            navigate(`/onbir-register/${data.sebitCredentials?.sebitToken}/${data.sebitCredentials?.name}/${data.sebitCredentials?.lastname}/${(data.sebitCredentials?.email != "" && data.sebitCredentials?.email)  ? data.sebitCredentials.email : "None"}`);
          }
        } catch (error: any) {
          console.log("sebit error", error.body.detail);
          setModalMessage("On1 User Not Found. Please contact us.");
          setIsModalOpen(true);
          // navigate("/login")
        }
      })();
    }
    setLoading(false);
  }, [token]);

  async function logOutTrigger() {
    dispatch(logOut());
    dispatch(logOutFavs());
    dispatch(logOutLogs());
  }

  return (
    <div
      style={{
        backgroundColor: "#2574FF",
        height: "100vh",
      }}
    >
      <CommonLoading loading={loading} />
      <CommonErrorModalNoShadow
        isOpen={isModalOpen}
        title="Error Occurred!"
        text={modalMessage}
        buttonText="Go To Login"
        onButtonFunc={() => navigate("/login")}
      />
    </div>
  );
};

export default On1InitialPage;
