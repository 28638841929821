import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { CommonLoading, HomeArticleSlider, HomeParentSolutionSlider } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { useNavigate, useParams } from "react-router";
import { makeFirstLettersUpperCase } from "../utils/commonFuncs";
import { contentSearch } from "../redux/searchSlice";
import { ITrainingSet, ITrainingSetList } from "../apiClient/models/trainingSet";

const Search: FC = () => {
  const { search_word } = useParams();
  const navigate = useNavigate();

  const { loading, trainingSetSearchItems, articleSearchItems } = useSelector(
    (state: RootState) => state.search
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (search_word != undefined || search_word != null) {
      dispatch(contentSearch({ query: search_word.replaceAll("-", " ") }));
    } else {
      navigate(-1);
    }
  }, [search_word]);

  const totalWatchTimeCalculator = (sec:number) => {
    if (sec == 0) {
      return 0;
    }

    return Math.floor(sec / 60);
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      {!loading ? (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          {trainingSetSearchItems.length > 0 ? (
            <>
              <Grid
                item
                xs={4}
                sm={8}
                md={12}
                style={{
                  textAlign: "start",
                  display: "flex",
                  marginBottom:"20px"
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#171725",
                  }}
                >
                  Ebeveyn Çözümleri
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#2574FF",
                    marginLeft: "10px",
                  }}
                >
                  “{makeFirstLettersUpperCase(search_word ? search_word.replaceAll("-", " ") : "")}”
                </Typography>
              </Grid>
              <Grid item xs={4} sm={8} md={12} style={{marginBottom:"32px"}}>
                <HomeParentSolutionSlider
                  items={
                    trainingSetSearchItems
                          .map((k: ITrainingSet) => {
                            return {
                              id: k.id,
                              title: k?.name,
                              video_article_count: 0,
                              text_article_count: 0 ,
                              total_watch_time: totalWatchTimeCalculator(
                                0
                              ),
                              watchPercent:k.watchPercent
                            };
                          })
                  }
                />
              </Grid>
            </>
          ) : null}
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", display: "flex", marginTop: "10px", marginBottom:"20px"}}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "28px",
                color: "#171725",
              }}
            >
              Makaleler
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "28px",
                color: "#2574FF",
                marginLeft: "10px",
              }}
            >
              “{makeFirstLettersUpperCase(search_word ? search_word.replaceAll("-", " ") : "")}”
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={12} style={{marginBottom:"32px"}}>
            <HomeArticleSlider 
                items={articleSearchItems.map((a) => {
                    return {
                        id:a.id,
                        title:a.name,
                        is_picture_uploaded:a.isPictureUploaded ? a.isPictureUploaded : false
                    }
                })}
             />
          </Grid>
        </Grid>
      ) : null}
    </Container>
  );
};

export default Search;
