import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserFavoriteModel, UserService,CreateUserFavoriteModel, FavoriteType } from "../client";
import { ReduxStoreStatus } from "../utils/constants";
import { GetAllFavorites, ToggleFavorite } from "../apiClient/services";
import { IFavorite } from "../apiClient/models/favorite";

export const getAllUserFavorites = createAsyncThunk(
    'favorite/getAllUserFavorites',
    async () => {
        try {
            return await GetAllFavorites()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addItemToFavorite = createAsyncThunk(
    'favorite/addItemToFavorite',
    async (payload: {favoritableType:"TrainingSets" | "Article" | "ParentSchool" | "Survey", favoritableId:string, name:string}) => {
        try {
            return await ToggleFavorite(payload.favoritableType, payload.favoritableId)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const removeItemFromFavorite = createAsyncThunk(
    'favorite/removeItemFromFavorite',
    async(payload:{favoritableType:"TrainingSets" | "Article" | "ParentSchool" | "Survey", favoritableId:string}) => {
        try {
            return await ToggleFavorite(payload.favoritableType, payload.favoritableId)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)
interface IaddItemToFavorite extends CreateUserFavoriteModel {
    name_of_favorite:string
}

interface IInitialState {
    loading: boolean,
    error: string,
    favorite_items: IFavorite[],
    status:ReduxStoreStatus 
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    favorite_items: [],
    status:ReduxStoreStatus.default
}

export const favoriteSlice = createSlice({
    name: "favorite",
    initialState,
    reducers: {
        logOutFavs (state){
            state.status=ReduxStoreStatus.default
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUserFavorites.pending, (state) => {
            state.loading = true
            state.status = ReduxStoreStatus.default
            state.error = ''
        })
        builder.addCase(getAllUserFavorites.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.favorite_items = action.payload.data
            state.status = ReduxStoreStatus.fetched
        })
        builder.addCase(getAllUserFavorites.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
            state.status = ReduxStoreStatus.default
        })
        builder.addCase(addItemToFavorite.pending, (state, {meta}) => {
            state.loading = true
            state.error = ''
            const _newFavorite:IFavorite = {
                id: 0,
                userId:"",
                favoriteId: meta.arg.favoritableId,
                favoriteType: meta.arg.favoritableType,
                createdAt: new Date(),
                updatedAt: new Date(),
                favoriteData: {
                    id: Number(meta.arg.favoritableId),
                    name:meta.arg.name
                }
            }

            state.favorite_items.unshift(_newFavorite)
        })
        builder.addCase(addItemToFavorite.fulfilled, (state,action) => {
            state.loading = false
        })
        builder.addCase(addItemToFavorite.rejected, (state,action) => {
            state.loading = false
            if(state.favorite_items.length > 0 && state.favorite_items[0].favoriteId == action.meta.arg.favoritableId){
                state.favorite_items = state.favorite_items.splice(0,1)
            }
        })
        builder.addCase(removeItemFromFavorite.pending, (state, {meta}) => {
            state.loading=true
            state.error = ''
            const removedItem = state.favorite_items.find((e) => e.favoriteId === meta.arg.favoritableId && e.favoriteType === meta.arg.favoritableType)
            state.favorite_items = state.favorite_items.filter(item => item.favoriteId !== removedItem?.favoriteId)
        })
        builder.addCase(removeItemFromFavorite.fulfilled, (state,action) => {
            state.loading = false
        })
        builder.addCase(removeItemFromFavorite.rejected, (state,action) => {
            state.loading = false
        })
    }
})

export const {logOutFavs} = favoriteSlice.actions
export default favoriteSlice.reducer