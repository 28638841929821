import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { AppointmentCard, HeadingTitle } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { fetchAppointments } from "../redux/appointmentSlice";

const Appointments: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { appointments, loading, currentPage } = useSelector(
    (state: RootState) => state.appointment
  );
  const { user } = useSelector((state: RootState) => state.authMicro);

  const [perPage, setPerPage] = useState(20);
  const [dates, setDates] = useState<string[]>([]);

  useEffect(() => {
    if(user?.id != null) {
      dispatch(
        fetchAppointments({
          page: currentPage,
          perPage,
          advisorId: "",
          userId: user?.id.toString() || "",
          sort: "upcoming",
          name: "",
          startDate: "",
          endDate: "",
        })
      );
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    const uniqueDates = new Set(dates);
    if (appointments.length > 0) {
      for (const appointment of appointments) {
        const start = appointment.startedAt.toString().split("T")[0].trim();
        uniqueDates.add(start);
      }
    }
    setDates(Array.from(uniqueDates));
  }, [appointments]);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Randevularım" _marginBottom="0px" />
        </Grid>
        {dates.map((e) => (
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            lg={12}
            style={{ textAlign: "start" }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "28px",
                color: "#272766",
                marginBottom: "20px",
                marginLeft: "20px",
              }}
            >
              {e}
            </Typography>
            {appointments.map((k) => {
              if (k.startedAt.toString().split("T")[0].trim() === e) {
                return (
                  <AppointmentCard
                    startTime={k.startTime.slice(0,5)}
                    endTime={k.endTime.slice(0, 5)}
                    name={k.advisor?.name}
                    imageUrl={k?.advisor?.userDetail?.imageUrl ?? "" }
                    url={
                      process.env.REACT_APP_ENV === "production" ? `https://onetoone.parentwiser.com/dashboard/live/${k.id}` : `https://onetoone-test.parentwiser.com/dashboard/live/${k.id}`
                    }
                  />
                );
              }
            })}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Appointments;
