import { FC, MouseEvent } from "react";
import { Grid, Typography } from "@mui/material";
import { HeartEmpty, HeartRed } from "../assests";
import { Buckets } from "../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { FavoriteType } from "../client";
import { useNavigate } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  addContentToFavorite: (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => void;
  removeContentFromFavorite: (e: MouseEvent, id: string) => void;
}

const FavoriteParentSolutionSliderItem: FC<Props> = ({
  id,
  title = "",
  addContentToFavorite,
  removeContentFromFavorite,
}) => {
  const navigate = useNavigate();
  const { favorite_items } = useSelector((state: RootState) => state.favorite);

  const cardStyle = {
    display: "flex",
    backgroundColor: "#F9F9F9",
    borderRadius: "24px",
    padding: 24,
  };

  const imageGridStyle = {
    background: `url(${`${Buckets.ParentSolutionBucket}/${id}.jpg`}), #D9D9D9`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "150px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "start",
    marginBottom: "16px",
    padding: 10,
    cursor: "pointer",
  };
  return (
    <Grid container style={cardStyle}>
      <Grid
        item
        xs={12}
        style={imageGridStyle}
        onClick={() => navigate(`/parentsolution/${id}`)}
      >
        {favorite_items.some((e) => {
          return e.favoriteType == "TrainingSets" && e.favoriteId == id;
        }) ? (
          <img
            src={HeartRed}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={(e) => removeContentFromFavorite(e, id)}
          />
        ) : (
          <img
            src={HeartEmpty}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={(e) => addContentToFavorite(e, id, title)}
          />
        )}{" "}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left", marginBottom: "19px" }}>
        <Typography
          style={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            lineHeight: "28px",
            color: "#000000",
            fontWeight: "700",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/parentsolution/${id}`)}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FavoriteParentSolutionSliderItem;
