import { FC, useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  CommonButton,
  CommonLabel,
  CommonTextField,
  CommonAlert,
  CommonLoading,
  CommonCheckbox,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../redux/store/store";
import { useNavigate, useParams } from "react-router-dom";
import { setCredentialsForSebit } from "../redux/clientStore/userSlice/userSlice";
import { MbaLogo, NameLogoBlue, PhoneMockRegister } from "../assests";
import { On1Service, SebitService } from "../client";
import { authClient, userClient } from "../apiClient/apiClient";

const theme = createTheme();

const On1Register: FC = () => {
  const { access_token } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const { token, name, surname, username } = useParams();
  const [localError, setLocalError] = useState<string>("");
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (access_token != "") {
      navigate("/home");
    }
  }, [access_token]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      setLocalLoading(true);
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const sebitRegisterResponse = await userClient().post("/on1/register", {
        email: data.get("username") as string,
        password: data.get("password") as string,
        sebitToken: token as string,
      });

      if (sebitRegisterResponse.data.success) {
        const checkUser = await authClient().post("/on1/login", {
          on1Token: token,
        });
        const data = checkUser.data.data;

        if (data.hasRegistered) {
          dispatch(setCredentialsForSebit(data.appAccessCredentials?.accessToken))
          setTimeout(() => navigate("/home"), 500);
        } else {
          navigate(
            `/onbir-register/${data.sebitCredentials?.sebitToken}/${
              data.sebitCredentials?.name
            }/${data.sebitCredentials?.lastname}/${
              data.sebitCredentials?.email != "" &&
              data.sebitCredentials?.email
                ? data.sebitCredentials.email
                : "None"
            }`
          );
        }
      } else {
        setLocalError(sebitRegisterResponse?.data?.message || "-");
      }
      setLocalLoading(false);
    } catch (error: any) {
      setLocalError(error?.detail || "-");
      setLocalLoading(false);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CommonLoading loading={localLoading} />
      <CommonAlert
        _type={"error"}
        _open={localError != "" ? true : false}
        _onClose={() => setLocalError("")}
        _message={"Kullanıcı Kayıtlı"}
      />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            backgroundColor: { md: "#2574FF" },
            display: { xs: "none", sm: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={PhoneMockRegister} alt="" width={460} height={560} />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: { md: 7, lg: 7 },
              paddingRight: { md: 7, lg: 7 },
            }}
          >
            <img src={NameLogoBlue} alt="" />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginTop: "6%",
              }}
            >
              Merhaba 👋
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#2574FF",
                marginBottom: "5%",
              }}
            >
              {name ? name : ""} {surname ? surname : ""}
            </Typography>
            <div
              style={{
                textAlign: "start",
                width: "100%",
                marginBottom: "25px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "28px",
                  color: "#171725",
                }}
              >
                ParentWiser Premium Hesabını Oluştur
              </Typography>
            </div>

            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div style={{ textAlign: "start", width: "100%" }}>
                <CommonLabel label="E-posta" />
                <CommonTextField
                  _placeHolder="E-postanı adresini gir"
                  formName="username"
                  _defaultValue={username == "None" ? "" : username}
                />
              </div>
              <div
                style={{ textAlign: "start", width: "100%", marginTop: "3%" }}
              >
                <CommonLabel label="Şifre" />
                <CommonTextField
                  _placeHolder="Şifreni gir"
                  _type="password"
                  formName="password"
                />
              </div>
              <div
                style={{
                  textAlign: "start",
                  marginTop: "4%",
                  marginBottom: "2%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CommonCheckbox _required={true} />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#2574FF",
                    marginLeft: "5px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(`/official-document/user_agreement`, "_blank")
                  }
                >
                  Kullanıcı Sözleşmesi
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#9393AA",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                >
                  ve
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#2574FF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(`/official-document/kvkk`, "_blank")
                  }
                >
                  KVKK Aydınlatma Metni
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#9393AA",
                  }}
                >
                  ’ni onaylıyorum.
                </Typography>
              </div>
              <div
                style={{
                  textAlign: "start",
                  marginBottom: "4%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CommonCheckbox />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#2574FF",
                    marginLeft: "5px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      `/official-document/communication_permission`,
                      "_blank"
                    )
                  }
                >
                  İletişim İzni
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#9393AA",
                  }}
                >
                  ’ni okudum/ kabul ediyorum.
                </Typography>
              </div>
              <CommonButton text="Premium Hesabını Oluştur" />
            </form>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#171725",
                textAlign: "start",
                marginTop: "24px",
              }}
            >
              Oluşturduğun bu hesapla AppStore ya da Google Play’den indirdiğin
              ParentWiser uygulamasından, bilgisayarından, tabletinden yani
              istediğin her yerden ParentWiser hesabına girebilirsin.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default On1Register;
