import "./App.css";
import { RouterProvider } from "react-router";
import { routerItems } from "./utils/routers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store/store";
import { OpenAPI } from "./client";
import {
  BecomePremiumModal,
  MobilDeviceDetected,
  MultipleDeviceErrorModal,
  RateContentModal,
  ShareContentModal,
} from "./components";
import {
  closeBecomePremiumModal,
  closeShareContentModal,
  closeContentEvaluateModal,
} from "./redux/generalControllerSlice";
import { isMobile } from "react-device-detect";
import TagManager from "react-gtm-module";

function App() {
  const { access_token } = useSelector((state: RootState) => state.auth);
  const {
    become_premium_modal_open,
    share_content_modal_open,
    rate_content_modal_open,
    multipleDeviceErrorModalOpen
  } = useSelector((state: RootState) => state.generalController);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
  }, [OpenAPI.TOKEN]);

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
  }, []);

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const tagManagerArgs = {
    gtmId: "GTM-MQ3N5CL3",
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <div className="App">
      <RouterProvider router={routerItems} />
      <BecomePremiumModal
        isOpen={become_premium_modal_open}
        onCancelButtonFunc={() => dispatch(closeBecomePremiumModal())}
      />
      <ShareContentModal
        isOpen={share_content_modal_open}
        onCancelButtonFunc={() => dispatch(closeShareContentModal())}
      />
      <RateContentModal
        isOpen={rate_content_modal_open}
        onCancelButtonFunc={() => dispatch(closeContentEvaluateModal())}
      />
      <MultipleDeviceErrorModal 
        isOpen={multipleDeviceErrorModalOpen}
      />
    </div>
  );
}

export default App;
