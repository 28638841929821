import { Container, Grid } from "@mui/material";
import { FC, useEffect, MouseEvent, useState } from "react";
import {
  ArticleListingItem,
  CommonLoading,
  HeadingTitle,
  HomeArticleSlider,
  PaginationItem,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getFilteredArticles } from "../redux/articleSlice";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import { ArticleType, FavoriteType } from "../client";
import { shuffleAndGetContent } from "../utils/commonFuncs";
import { ISuggestedArticle } from "../utils/commonModels";

const Articles: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { articles, loading } = useSelector(
    (state: RootState) => state.article
  );

  const [suggested, setSuggested] = useState<Array<ISuggestedArticle>>(
    [] as ISuggestedArticle[]
  );

  const DATA_LENGTH_LIMIT = 30


  useEffect(() => {
      dispatch(
        getFilteredArticles({
          perPage:DATA_LENGTH_LIMIT,
          page:1,
          name:"",
          type:"text"
        })
      );
  }, []);

  useEffect(() => {
    if (suggested.length == 0 && articles.data.length > 0){
      setSuggested(shuffleAndGetContent(articles.data,5).map((e)=> {return({id:e.id, title:e.name, is_picture_uploaded:e.isPictureUploaded ? e.isPictureUploaded : false})}))
    }
  }, [articles.data])
  

  const addContentToFavorite = (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => {
    try {
      e.stopPropagation();
      dispatch(
        addItemToFavorite({
          favoritableId: id,
          favoritableType: "Article",
          name: name_of_content
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = (e: MouseEvent, id: string) => {
    try {
      e.stopPropagation();
      dispatch(removeItemFromFavorite({ favoritableId: id, favoritableType:"Article" }));
    } catch (error) {}
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12,lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12}style={{ textAlign: "start" }}>
          <HeadingTitle
            title="Sizin İçin Seçtiğimiz Makaleler"
            _marginBottom="0px"
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12} lg={12}>
          <HomeArticleSlider
            items={suggested}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          lg={12}
          style={{
            marginTop: "28px",
            textAlign: "start",
          }}
        >
          <HeadingTitle title="Tüm Makaleler" _marginBottom="0px" />
        </Grid>
        {!loading &&
          articles.data.map((e, index) => {
            return (
              <Grid item xs={4} sm={8} md={3} key={index}>
                <ArticleListingItem
                  id={e.id}
                  title={e.name}
                  is_picture_uploaded={
                    e?.isPictureUploaded ? e.isPictureUploaded : false
                  }
                  addContentToFavorite={(e, id, name_of_content) =>
                    addContentToFavorite(e, id, name_of_content)
                  }
                  removeContentFromFavorite={(e, id) =>
                    removeContentFromFavorite(e, id)
                  }
                />
              </Grid>
            );
          })}
        <Grid item xs={4} sm={8} md={12} style={{ marginTop: "10px" }}>
          <PaginationItem currentPage={articles.currentPage} totalPages={Math.ceil(articles.total / DATA_LENGTH_LIMIT)} onPageChange={(e) => dispatch(getFilteredArticles({perPage:DATA_LENGTH_LIMIT, page:e, name:"", type:"text"}))}/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Articles;
