import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DefaultService, Body_login_for_access_token_token_post, UserProfileModel, OpenAPI, UserService, UserPatchModel, UserCreateModel, OauthProvider, Body_change_profile_picture_user_change_profile_picture_post, UserSubscriptionDto } from '../client';
import { authClient } from '../apiClient/apiClient';
import { AxiosResponse } from 'axios';

export const login = createAsyncThunk(
  'auth/login',
  async (payload: Body_login_for_access_token_token_post) => {
    try {
      return await DefaultService.loginForAccessTokenTokenPost(payload)
    } catch (error: any) {
      console.log(error)
      throw new Error(error?.message)
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (payload: UserCreateModel) => {
    try {
      return await UserService.createUserUserUserPost(payload)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (payload: { user: UserPatchModel }) => {
    try {
      return await UserService.patchUserUserUserPatch(payload.user)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)

export const changePasswordIn = createAsyncThunk(
  'auth/changePasswordIn',
  async (payload: { new_password: string }) => {
    try {
      return await UserService.updatePasswordUserUpdatePasswordPost(payload.new_password)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)

export const removeFamilyMember = createAsyncThunk(
  'auth/removeFamilyMember',
  async (payload: { partner_id: string }) => {
    try {
      return await UserService.deletePartnerFromMyAccountUserDeletePartnerFromMyAccountDelete(payload.partner_id)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)


export const uploadProfilePicture = createAsyncThunk(
  'auth/uploadProfilePicture',
  async (payload:Body_change_profile_picture_user_change_profile_picture_post) => {
    try {
      return await UserService.changeProfilePictureUserChangeProfilePicturePost(payload)
    } catch (error:any) {
      throw new Error(error?.message)
    }
  }
)

interface IInitialState {
  access_token: string
  refresh_token: string
  loading: boolean
  error: string
  // profile: UserProfileModel
  subscription_detail: UserSubscriptionDto
}

const initialState: IInitialState = {
  access_token: "",
  refresh_token: "",
  loading: false,
  error: "",
  // profile: {} as UserProfileModel,
  subscription_detail: {} as UserSubscriptionDto
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError(state, action) {
      state.error = action.payload
    },
    setLoading(state,action) {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = ''
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      OpenAPI.TOKEN = action.payload.access_token
      state.loading = false;
      state.error = ''
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.error = "Kullanıcı adı veya şifre yanlış"
    })
    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.error = ''
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      OpenAPI.TOKEN = action.payload.access_token
      state.loading = false;
      state.error = ''
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
      console.log("err", action.error)
    })
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      // state.profile = { ...state.profile, ...action.payload }
      state.loading = false
      state.error = ''
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
    builder.addCase(changePasswordIn.pending, (state) => {
      state.loading = true
    })
    builder.addCase(changePasswordIn.fulfilled, (state, action) => {
      state.loading = false
      state.error = ''
    })
    builder.addCase(changePasswordIn.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
    builder.addCase(removeFamilyMember.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(removeFamilyMember.fulfilled, (state, action) => {
      if (action.payload.is_request_successful) {
        // const newPartnerIds = state.profile.partner_ids?.filter((e) => e != action.meta.arg.partner_id)
        // state.profile.partner_ids = newPartnerIds
      }
      state.loading = false
      state.error = ''
    })
    builder.addCase(removeFamilyMember.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
    builder.addCase(uploadProfilePicture.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(uploadProfilePicture.fulfilled, (state, action) => {
      // state.profile.is_profile_picture_uploaded = true
      state.loading = false
      state.error = ''
    })
    builder.addCase(uploadProfilePicture.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
  },
});

export const { setAuthError, setLoading } = authSlice.actions
export default authSlice.reducer;