import { AxiosResponse } from "axios";
import { Appointment } from "../models/appointment";
import { appointmentClient } from "../apiClient";

export const GetAppointments = async (perPage: number = 10, page: number = 1, advisorId: string = "", userId: string = "", sort: "upcoming" | "past" | "", name: string = "", startDate: string = "", endDate: string = ""): Promise<{ data: Appointment[], total: number }> => {
    try {
        const response: AxiosResponse<{ data: Appointment[], total: number }> = await appointmentClient().get('/appointment', {
            params: {
                perPage,
                page,
                advisorId,
                userId,
                sort,
                name,
                startDate,
                endDate
            }
        })
        return response.data
    } catch (error) {
        console.error("Failed to fetch appointments:", error);
        throw error;
    }
}