import { MenuHome, MenuParentSchool, MenuParentSolution, MenuSurvey, MenuArticle, MenuPLogo, MenuSupport, MenuHeart, MenuUserAdd, MenuChildren, MenuHomeSelected, MenuParentSchoolSelected, MenuParentSolutionSelected, MenuSurveySelected, MenuArticleSelected, MenuPLogoSelected, MenuChildrenSelected, MenuUserAddSelected, MenuSupportSelected, MenuHeartSelected, MenuSettings, MenuSettingsSelected, MenuPersonalInformation, MenuPersonalInformationSelected, MenuMySubs, MenuMySubsSelected, BookAndQuestionMark, MenuAskWiserSelected, MenuAskWiser, MenuGift, MenuGiftSelected, MenuAppointment, MenuAppointmentSelected } from "../assests"
import { MarriageStatus } from "../client"

export const ContentMenuItems = [
    {
        name: "Anasayfa",
        icon: MenuHome,
        url: "/home",
        iconSelected: MenuHomeSelected
    },
    {
        name: "Anne Baba Okulu",
        icon: MenuParentSchool,
        url: "/parentschool",
        iconSelected:MenuParentSchoolSelected
    },
    {
        name: "Ebeveyn Çözümleri",
        icon: MenuParentSolution,
        url: "/parentsolutions",
        iconSelected:MenuParentSolutionSelected
    },
    {
        name: "Anketler",
        icon: MenuSurvey,
        url: "/surveys",
        iconSelected:MenuSurveySelected
    },
    {
        name: "Makaleler",
        icon: MenuArticle,
        url: "/articles",
        iconSelected:MenuArticleSelected
    },
    {
        name:"Sorularla Öğreniyorum",
        icon: BookAndQuestionMark,
        url:"/learn-with-question",
        iconSelected:BookAndQuestionMark
    },
    {
        name:"Wiser'a Sor",
        icon: MenuAskWiser,
        url:"/ask-wiser",
        iconSelected:MenuAskWiserSelected
    },
    {
        name: "Favoriler",
        icon: MenuHeart,
        url: "/favorites",
        iconSelected:MenuHeartSelected
    },
    {
        name: "Randevularım",
        icon: MenuAppointment,
        url: "/appointments",
        iconSelected:MenuAppointmentSelected
    }
]

export const SubMenuItems = [
    // {
    //     name: "Hediye Et",
    //     icon: MenuGift,
    //     url: "/gift-to-friend",
    //     iconSelected:MenuGiftSelected
    // },
    {
        name:"Ayarlar",
        icon:MenuSettings,
        url:"/settings",
        iconSelected:MenuSettingsSelected
    },
    {
        name: "Çocuklarım",
        icon: MenuChildren,
        url: "/children",
        iconSelected:MenuChildrenSelected
    },
    {
        name: "Kişisel Bilgilerim",
        icon: MenuPersonalInformation,
        url: "/personal-informations",
        iconSelected:MenuPersonalInformationSelected
    },
    {
        name: "Aile Üyeliğini Yönet",
        icon: MenuUserAdd,
        url: "/manage-family-member",
        iconSelected:MenuUserAddSelected
    },
    {
        name: "Destek",
        icon: MenuSupport,
        url: "/support",
        iconSelected:MenuSupportSelected
    },
    {
        name: "Aboneliğim",
        icon: MenuMySubs,
        url: "/my-subscription",
        iconSelected:MenuMySubsSelected
    },
    {
        name: "ParentWiser Nedir?",
        icon: MenuPLogo,
        url: "/whatisparentwiser",
        iconSelected:MenuPLogoSelected
    },
]

export const Buckets = {
    ArticleImageBucket: "https://storage.googleapis.com/keen-scion-372914-article-hero-pictures",
    ParentSolutionBucket: "https://storage.googleapis.com/keen-scion-372914-training-set-hero-pictures",
    UserProfilePictureBucket: "https://storage.googleapis.com/keen-scion-372914-profile-pictures",
    ArticleVideoBucket: "https://storage.googleapis.com/keen-scion-372914-article-videos",
    BulettinBucket: "https://storage.googleapis.com/keen-scion-372914-bulletin-cover-images",
    QuoteBucket: "https://storage.googleapis.com/keen-scion-372914-daily-quotes-cover-images",
    ParentSchoolModuleImageBucket:"https://storage.googleapis.com/keen-scion-372914-ps-module-hero-pictures"
}

export const enum ReduxStoreStatus {
    fetched = "fetched",
    default = "default"
}

export const ParentTypesExplained =
    [
        {
            key: "mother",
            name: "Anne"
        },
        {
            key: "father",
            name: "Baba"
        },
        {
            key: "grandfather",
            name: "Büyükbaba"
        },
        {
            key: "grandmother",
            name: "Büyükanne"
        },
        {
            key: "babykeeper",
            name: "Bakıcı"
        },
        {
            key: "other",
            name: "Diğer"
        }
    ]

export const MarriageStatusesExplained = [
    {
        key:MarriageStatus.MARRIED,
        name:"Evli"
    },
    {
        key:MarriageStatus.SINGLE,
        name:"Bekar"
    },
    {
        key:MarriageStatus.DIVORCED,
        name:"Boşanmış"
    },
    {
        key:MarriageStatus.OTHER,
        name:"Diğer"
    },
]

export const LettersMapped = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]

export const PurchasePackageDescriptionMatters = [
    "Anne Baba Okulu",
    "Bilimsel Anketler",
    "Kişiselleştirilmiş İçerik",
    "Reklamları Kaldırın",
    "Tüm cihazlardan erişim (Bilgisayar, Tablet, Telefon)",
    "Ayda 1 kez Özgür Bolat'la Premium kullancılara özel soru cevap seansı",
    "Aile üyeliği ile ikinci premium hesap ücretsiz."
]

export const OfficialDocumentEnums = [
    {
        slug:"kvkk",
        title:"KİŞİSEL VERİLERİN KORUNMASI KANUNU"
    },
    {
        slug:"communication_permission",
        title:"İLETİŞİM İZNİ"
    },
    {
        slug:"user_agreement",
        title:"Kullanım Sözleşmesi"
    }
]

export const WebDiscountRate:number = 15
export const WebDiscountCoupon:string = "web15"

export const IngDiscountRate:number = 40
export const IngDiscountCoupon:string = "ing40"

export const enum MetaEventNames {
    purchaseWithLogin = "PURCHASE_WITH_LOGIN",
    purchaseWithoutLogin = "PURCHASE_WITHOUT_LOGIN",
    purchaseWithoutLoginPageAddressPhase = "PURCHASE_WITHOUT_LOGIN_PAGE_ADDRESS_PHASE",
    purchaseWithLoginPageAddressPhase = "PURCHASE_WITH_LOGIN_PAGE_ADDRESS_PHASE",
    pricingPackagesWithLogin = "PRICING_PACKAGES_WITH_LOGIN"
}

export const IngDiscountBinNumbers:string[] = [
    "400684",
    "408579",
    "420322",
    "420323",
    "420324",
    "510151",
    "517946",
    "526973",
    "526975",
    "531401",
    "532443",
    "542965",
    "542967",
    "547765",
    "550074",
    "554570",
    "979224",
    "408581",
    "423091",
    "490808",
    "535270",
    "676402",
    "979242"
]

export const IngMax50DiscountCoupon:string = "ing50-6f49-4b23-b22a-108ca781aa67"
