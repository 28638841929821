import { AxiosResponse } from "axios";
import { SearchResponseDto } from "../models/search";
import { contentClient } from "../apiClient";

export const ContentSearch = async(query:string):Promise<SearchResponseDto> => {
    try {
            const response: AxiosResponse<SearchResponseDto> = await contentClient().get(`/search?query=${query}`);
            return response.data;
        } catch (error) {
            console.error('Error search contents:', error);
            throw new Error('Failed to search contents');
        }
}