import { FC, useState, useEffect } from "react";
import { NameLogoBlue } from "../assests";
import { Container, Divider, Grid, Typography } from "@mui/material";
import {
  CommonButton,
  CommonLoading,
  RegisterFlowProgressBar,
  RegisterSetAreaOfInterests,
  RegisterSetChildren,
  RegisterSetName,
  RegisterSetParentType,
  RegisterWhatBringsYou,
} from "../components";
import { IRegisterFlowInputs } from "../utils/commonModels";
import { Gender, MarriageStatus, OpenAPI, ParentType } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  getAreaOfInterests,
  getWhatBringsYouOptions,
} from "../redux/areaOfInterestSlice";
import { updateUser } from "../redux/authSlice";
import { useNavigate } from "react-router";
import { getProfile } from "../redux/clientStore/userSlice/userSlice";
import { SetAreaOfInterests, SetWhatBringUser, UpdateUser } from "../apiClient/services";
import { addSingleChild } from "../redux/childSlice";

const RegisterFlow: FC = () => {
  const navigate = useNavigate()
  const [registerUser, setRegisterUser] = useState<IRegisterFlowInputs>(
    {} as IRegisterFlowInputs
  );
  const [step, setStep] = useState<number>(1);

  const {
    accessToken,
    user,
    loading: authLoading,
  } = useSelector((state: RootState) => state.authMicro);
  const { loading, interests, whatBringsYouOptions } = useSelector(
    (state: RootState) => state.areaOfInterest
  );
  const { loading: childLoading } = useSelector(
    (state: RootState) => state.child
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    let date = new Date();
    const subDate = date.toISOString().split("T")[0];

    setRegisterUser({
      ...registerUser,
      children: {
        ...registerUser.children,
        date_of_birth: subDate.toString() as string,
      },
    });
    
    dispatch(getAreaOfInterests());
    dispatch(getProfile());
  }, []);

  const titleList = [
    {
      order: 1,
      title: "Kişisel Bilgilerin - 1/2",
    },
    {
      order: 1.1,
      title: "Kişisel Bilgilerin - 2/2",
    },
    {
      order: 2,
      title: "Çocuğun Bilgileri - 1/1",
    },
    {
      order: 3.1,
      title: "Kişiselleştir - 2/2",
    },
  ];

  const stepItemPicker = () => {
    switch (step) {
      case 1:
        return (
          <RegisterSetName
            name={registerUser.name}
            onSetName={(e: string) =>
              setRegisterUser({ ...registerUser, name: e })
            }
          />
        );
      case 1.1:
        return (
          <RegisterSetParentType
            parent_type={registerUser.parent_type}
            onSetParentType={(e: ParentType) =>
              setRegisterUser({ ...registerUser, parent_type: e })
            }
          />
        );
      case 2:
        return (
          <RegisterSetChildren
            children={registerUser.children}
            onChildNameChanged={(e: string) =>
              setRegisterUser({
                ...registerUser,
                children: { ...registerUser.children, name: e },
              })
            }
            onChildBirthdayChanged={(e: string) =>
              setRegisterUser({
                ...registerUser,
                children: { ...registerUser.children, date_of_birth: e },
              })
            }
            onChildGenderdayChanged={(e: Gender) =>
              setRegisterUser({
                ...registerUser,
                children: { ...registerUser.children, gender: e },
              })
            }
          />
        );
      case 3.1:
        return (
          <RegisterSetAreaOfInterests
            selected_interests={registerUser.interested_areas}
            interests={interests}
            addInterest={(e: string) => addNewInterest(e)}
            removeInterest={(e: string) => removeUserInterest(e)}
          />
        );
      default:
        break;
    }
  };

  const addNewInterest = (e: string) => {
    const copyInterests =
      registerUser?.interested_areas &&
      registerUser?.interested_areas.length > 0
        ? registerUser.interested_areas
        : [];
    copyInterests.push(e);
    setRegisterUser({ ...registerUser, interested_areas: copyInterests });
  };

  const removeUserInterest = (e: string) => {
    const copyInterests =
      registerUser?.interested_areas &&
      registerUser?.interested_areas.length > 0
        ? registerUser.interested_areas.filter((k) => k !== e)
        : [];
    setRegisterUser({ ...registerUser, interested_areas: copyInterests });
  };

  const onContunieTriggered = async () => {
    switch (step) {
      case 1:
        setStep(1.1);
        break;
      case 1.1:
        setStep(2);
        break;
      case 2:
        setStep(3.1);
        break;
      case 3.1:
        await UpdateUser(user?.id as string, {
          email: user?.email,
          phone: user?.phone,
          name: registerUser?.name ?? "",
          gender: "other",
          parentType: registerUser.parent_type,
          marriageStatus: "other",

        })
        await dispatch(addSingleChild({name:registerUser.children.name, dateOfBirth:registerUser.children.date_of_birth, gender: registerUser.children.gender, schoolId:null, classId:null, parentIds:[user?.id as string]}));
        await SetAreaOfInterests(registerUser.interested_areas)
        navigate('/home')
        break;
      default:
        break;
    }
  };

  const onBackTriggered = () => {
    switch (step) {
      case 3.1:
        setStep(2);
        break;
      case 2:
        setStep(1.1);
        break;
      case 1.1:
        setStep(1);
        break;
      default:
        break;
    }
  };

  const onContunieDisabled = () => {
    switch (step) {
      case 1:
        return registerUser?.name?.trim().split(" ").length >= 2 ? false : true;
      case 1.1:
        return registerUser?.parent_type ? false : true;
      case 2:
        return registerUser?.children?.date_of_birth &&
          registerUser?.children?.name &&
          registerUser?.children?.gender
          ? false
          : true;
      case 3.1:
        return registerUser?.interested_areas &&
          registerUser?.interested_areas.length > 0
          ? false
          : true;
      default:
        break;
    }
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={loading || authLoading || childLoading} />
      <Container maxWidth="lg" style={{ marginTop: "40px" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={4} sm={8} md={12} style={{ marginBottom: "60px" }}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "26px",
                color: "#171725",
              }}
            >
              {titleList.find((e) => e.order == step)?.title}
            </Typography>
            <RegisterFlowProgressBar
              customCSS={{ marginTop: "13px" }}
              currentStep={step}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              width: { xs: "95%", sm: "95%", md: "50%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {stepItemPicker()}
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              width: { xs: "95%", sm: "95%", md: "50%" },
              marginTop: "35px",
              marginBottom: "40px",
            }}
          >
            <CommonButton
              text="Devam Et"
              onClick={() => onContunieTriggered()}
              disabled={onContunieDisabled()}
            />
            {step == 1 ? null : (
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "26px",
                  color: "#9CA4AB",
                  marginTop: "16px",
                  cursor: "pointer",
                }}
                onClick={() => onBackTriggered()}
              >
                Geri
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RegisterFlow;
