import { FC, useEffect, useState } from "react";
import { CommonLoading } from "../components";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store/store";
import { setCredentialsForSebit } from "../redux/clientStore/userSlice/userSlice";
import axios from "axios";
import { OpenAPI } from "../client";

const LoginWithToken: FC = () => {
  const { token } = useParams<{ token: string }>();

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  useEffect(() => {
    try {
      const checkToken = async () => {
        try {
          await axios.get(`${OpenAPI.BASE}/user/get-profile-details`,{
            headers: {
              Authorization: `Bearer ${token}`
            },
          })
          dispatch(setCredentialsForSebit(token))
          setTimeout(() => navigate('/home') ,500);
        } catch (error) {
          setTimeout(() => navigate('/login') ,500);
          return
        }

      }
        checkToken()
    } catch (error) {
        console.log("err")        
    }
  }, [token])
  

  return (
    <div
      style={{
        backgroundColor: "#2574FF",
        height: "100vh",
      }}
    >
      <CommonLoading loading={true} />
    </div>
  );
};

export default LoginWithToken;
